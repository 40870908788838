<template>
    <detalhe titulo="Status" :size="size">
        <status :status="checkIn?.statusDescr"></status>
    </detalhe>
    <detalhe titulo="Tipo" :size="size">
        {{ checkIn?.tipoDescr }}
    </detalhe>
    <detalhe titulo="CheckIn em" :size="size">
        {{ $dateFormat(checkIn?.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="CPF" :size="size">
        {{ checkIn?.cpf }}
    </detalhe>
    <detalhe titulo="Nome" :size="size">
        {{ checkIn?.nome }}
    </detalhe>
    <detalhe titulo="Email" :size="size">
        {{ checkIn?.email }}
    </detalhe>
    <detalhe titulo="Atualizado em" :size="size">
        {{ $dateFormat(checkIn?.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
</template>

<script>
export default {
    props: {
        checkIn: {
            type: Object,
        },
    },

    data() {
        return {
            size: 100,
        };
    },

    methods: {},
};
</script>
