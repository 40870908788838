<template>
    <painel titulo="CheckIn" icone="pi pi-comments" :refreshFunction="obterCheckIn">
        <div class="mb-4">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Dados Gerais">
                    <dados-gerais :checkIn="checkIn"></dados-gerais>
                </TabPanel>
                <TabPanel header="Objeto Identificação">
                    <vue-json-pretty v-if="checkIn" :data="JSON.parse(checkIn?.objetoIdentificacao)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Objeto Integração" v-if="checkIn && checkIn.objetoIntegracao">
                    <vue-json-pretty :data="JSON.parse(checkIn?.objetoIntegracao)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Retorno Integração" v-if="checkIn && checkIn.objetoRetorno">
                    <vue-json-pretty
                        :data="JSON.parse(checkIn?.objetoRetorno)"
                        :showDoubleQuotes="true"
                        v-if="checkIn && checkIn.objetoRetorno && isJsonString(checkIn.objetoRetorno)"
                    ></vue-json-pretty>
                    <span v-if="checkIn && checkIn.objetoRetorno && !isJsonString(checkIn.objetoRetorno)">{{ checkIn?.objetoRetorno }}</span>
                </TabPanel>
            </TabView>
        </div>
        <btn-voltar :route="{ name: 'AgenciaConceito_CheckIns_Listar' }"></btn-voltar>
    </painel>
</template>

<script>
import CheckInsService from './services';
import DadosGerais from './DadosGerais';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        DadosGerais,
        VueJsonPretty,
    },

    data() {
        return {
            checkIn: null,
            activeIndex: 0,
        };
    },

    methods: {
        obterCheckIn() {
            this.$store.dispatch('addRequest');
            CheckInsService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.checkIn = response.data;
                } else {
                    this.checkIn = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
    },

    computed: {},

    mounted() {
        this.obterCheckIn();
        if (this.$route.query.view == 'dados') {
            this.activeIndex = 1;
        }
    },
};
</script>
