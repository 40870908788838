import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_AGENCIACONCEITO_PORT}${process.env.VUE_APP_API_PATH}/agenciaconceito/checkins`;

export default {
    obterTodos(filtro) {
        return axiosJwt.get(
            `${api}?dataCheckInDe=${filtro.dataCheckInDe}&dataCheckInAte=${filtro.dataCheckInAte}`
        );
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/${id}`);
    },

};
